// Spacing Variables
$port-space-xxsm: .25rem; // 4px
$port-space-xsm: .5rem; // 8px
$port-space-sm: .75rem; // 12px
$port-space-xmd: 1rem; // 16px
$port-space-md: 1.5rem; // 24px
$port-space-lg: 2rem; // 32px
$port-space-xlg: 2.5rem; // 40px
$port-space-xxlg: 3rem; //48px

// Font Variables
$port-base-font-size: 16px;

// Layout Variables
$port-wrapper: 1100px;


// Px to Rem Function
$baseFontSize: $port-base-font-size;

@function rem($px, $base: $baseFontSize) {

    // if no units provided, give units
    @if (unitless($px)) {
        $px: $px * 1px;
    }

    @if (unitless($base)) {
        $base: $base * 1px;
    }

    @return $px / $base * 1rem;
}

// Breakpoints
$port-breakpoint-sm: rem(450px);
$port-breakpoint-md: rem(600px);
$port-breakpoint-xmd: rem(900px);
$port-breakpoint-lg: $port-wrapper;

