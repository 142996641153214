@import url(https://cdnjs.cloudflare.com/ajax/libs/10up-sanitize.css/8.0.0/sanitize.min.css);
@import url(https://fonts.googleapis.com/css?family=Red+Hat+Text:400,500,700|Work+Sans:400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Red+Hat+Text|Work+Sans&display=swap);
html,
body {
  font-family: 'Red Hat Text', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px; }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 300;
  margin: .8rem 0; }

b, strong {
  font-weight: bold; }

button {
  background: transparent;
  border: 0; }
  button:hover {
    cursor: pointer; }

a {
  font-family: 'Red Hat Text', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-decoration: none;
  color: #aaa; }
  a:visited {
    color: #aaa; }
  a:hover {
    color: #222; }

pre {
  line-height: 1.5;
  padding: 1rem;
  background-color: #f2f2f2;
  border-radius: .5rem;
  margin-bottom: 2rem;
  white-space: pre-wrap; }

input[type=range] {
  -webkit-appearance: none;
  /* Hides the slider so that custom slider can be made */
  width: 100%;
  /* Specific width is required for Firefox. */
  background: transparent;
  /* Otherwise white in Chrome */ }

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none; }

input[type=range]:focus {
  outline: none;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */ }

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent; }

/* WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 1.1rem;
  width: 1.1rem;
  border-radius: 1.1rem;
  background: #ffffff;
  cursor: pointer;
  margin-top: -.42rem;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  /* Add cool effects to your sliders! */ }

/* Firefox */
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  height: 1.1rem;
  width: 1.1rem;
  border-radius: 1.1rem;
  background: #ffffff;
  cursor: pointer; }

/* IE */
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  height: 1.1rem;
  width: 1.1rem;
  border-radius: 1.1rem;
  background: #ffffff;
  cursor: pointer; }

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: white;
  border-radius: 1.3px;
  border: 0.2px solid #010101; }

input[type=range]:focus::-webkit-slider-runnable-track {
  background: white; }

input[type=range]::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: white;
  border-radius: 1.3px;
  border: 0.2px solid #010101; }

input[type=range]::-ms-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent; }

input[type=range]::-ms-fill-lower {
  background: white;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; }

input[type=range]:focus::-ms-fill-lower {
  background: white; }

input[type=range]::-ms-fill-upper {
  background: white;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; }

input[type=range]:focus::-ms-fill-upper {
  background: white; }

h1,
h2,
h3,
h4,
h5 {
  margin: 1rem 0 1rem;
  font-weight: 400;
  line-height: 1.15; }

h1 {
  font-size: 1.802rem;
  margin: 1rem 0 2rem; }

h2 {
  font-size: 1.602rem;
  margin: 1rem 0 1.75rem; }

h3 {
  font-size: 1.424rem;
  margin: 1rem 0 1.5rem; }

h4 {
  font-size: 1.266rem;
  margin: 1rem 0 1.25rem; }

h5 {
  font-size: 1.125rem; }

p {
  color: #666;
  line-height: 1.5; }
  p b, p strong {
    color: #222; }

ul, ol {
  color: #333;
  margin-bottom: 2rem; }
  ul li, ol li {
    margin-bottom: .5rem;
    line-height: 1.5; }

.navigation {
  position: fixed;
  left: 0.75rem;
  top: 1.5rem;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 1000; }
  .navigation.nav-up {
    -webkit-transition: opacity 0.4s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.4s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: opacity 0.4s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.4s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: transform 0.4s cubic-bezier(0.895, 0.03, 0.685, 0.22), opacity 0.4s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: transform 0.4s cubic-bezier(0.895, 0.03, 0.685, 0.22), opacity 0.4s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.4s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0; }
  .navigation.nav-down {
    -webkit-transition: opacity 0.7s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: opacity 0.7s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: transform 0.7s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: transform 0.7s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.7s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; }
  .navigation:hover, .navigation.navBack {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px); }

.ham-menu {
  height: 4px;
  width: 60%;
  background-color: black;
  position: absolute;
  cursor: pointer;
  top: 30%;
  -webkit-transition: 0.4s all cubic-bezier(0.77, 0, 0.175, 1);
  transition: 0.4s all cubic-bezier(0.77, 0, 0.175, 1);
  border-bottom: 1px solid white; }
  .ham-menu:before {
    content: "";
    position: absolute;
    height: 4px;
    width: 40px;
    background-color: black;
    bottom: -12px;
    -webkit-transition: 0.4s all cubic-bezier(0.77, 0, 0.175, 1);
    transition: 0.4s all cubic-bezier(0.77, 0, 0.175, 1);
    left: 0;
    border-bottom: 1px solid white; }
  .ham-menu:after {
    content: "";
    position: absolute;
    height: 4px;
    width: 20px;
    background-color: black;
    bottom: 10px;
    -webkit-transition: 0.4s all cubic-bezier(0.77, 0, 0.175, 1);
    transition: 0.4s all cubic-bezier(0.77, 0, 0.175, 1);
    left: 0;
    border-bottom: 1px solid white; }
  .ham-menu.navBack {
    border-bottom: 1px solid transparent;
    height: 3px; }
    .ham-menu.navBack:after {
      -webkit-transform: rotate(-45deg) translateX(-6px) translateY(-0.5px);
              transform: rotate(-45deg) translateX(-6px) translateY(-0.5px);
      border-bottom: 1px solid transparent;
      height: 3px; }
    .ham-menu.navBack:before {
      -webkit-transform: rotate(45deg) scaleX(0.5) translateX(-29px) translateY(7.1px);
              transform: rotate(45deg) scaleX(0.5) translateX(-29px) translateY(7.1px);
      border-bottom: 1px solid transparent;
      height: 3px; }

.navigation-list {
  position: fixed;
  height: 105vh;
  width: 100vw;
  background-color: #fff;
  z-index: 998;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 0.75rem;
  top: -2.5vh;
  margin: 0; }
  .navigation-list .menu-item {
    display: block;
    width: 100%;
    color: #ccc;
    font-weight: 200;
    font-size: 1.5rem;
    cursor: pointer;
    margin-bottom: 20px;
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    opacity: 0; }
    .navigation-list .menu-item span {
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
      line-height: 1;
      padding-bottom: 0.75rem; }
      .navigation-list .menu-item span.hidden {
        -webkit-transition: 0.5s all cubic-bezier(0.77, 0, 0.175, 1);
        transition: 0.5s all cubic-bezier(0.77, 0, 0.175, 1);
        color: #ccc;
        display: none; }
    .navigation-list .menu-item:hover span {
      color: #333;
      width: auto; }
  .navigation-list.open .menu-item:nth-child(1) {
    -webkit-transition: 0.5s 0.95s all cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: 0.5s 0.95s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .navigation-list.open .menu-item:nth-child(2) {
    -webkit-transition: 0.5s 1s all cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: 0.5s 1s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .navigation-list.open .menu-item:nth-child(3) {
    -webkit-transition: 0.5s 1.05s all cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: 0.5s 1.05s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .navigation-list.open .menu-item:nth-child(4) {
    -webkit-transition: 0.5s 1.1s all cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: 0.5s 1.1s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .navigation-list.open .menu-item:nth-child(5) {
    -webkit-transition: 0.5s 1.15s all cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: 0.5s 1.15s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .navigation-list.open .menu-item {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; }
  .navigation-list.closing .menu-item:nth-child(1) {
    -webkit-transition: 0.4s 0.2s all cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: 0.4s 0.2s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .navigation-list.closing .menu-item:nth-child(2) {
    -webkit-transition: 0.4s 0.15s all cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: 0.4s 0.15s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .navigation-list.closing .menu-item:nth-child(3) {
    -webkit-transition: 0.4s 0.1s all cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: 0.4s 0.1s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .navigation-list.closing .menu-item:nth-child(4) {
    -webkit-transition: 0.4s 0.05s all cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: 0.4s 0.05s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .navigation-list.closing .menu-item:nth-child(5) {
    -webkit-transition: 0.4s 0s all cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: 0.4s 0s all cubic-bezier(0.165, 0.84, 0.44, 1); }
  .navigation-list.closing .menu-item {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    opacity: 0; }

.nav-list {
  position: absolute; }
  .nav-list::-webkit-scrollbar {
    width: 0 !important;
    background: transparent; }

.nav-list-enter {
  opacity: 0;
  pointer-events: none; }

.nav-list-enter-active {
  opacity: 1;
  -webkit-transition: opacity 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms;
  transition: opacity 800ms cubic-bezier(0.165, 0.84, 0.44, 1) 200ms; }

.nav-list-exit {
  opacity: 1; }

.nav-list-exit-active {
  opacity: 0;
  -webkit-transition: opacity 200ms;
  transition: opacity 200ms; }

.nav-list-exit-done {
  opacity: 0;
  pointer-events: none; }

@media (min-width: 28.125rem) {
  .navigation-list .menu-item {
    font-size: 2rem; }
  .navigation-list .menu-item span.hidden {
    display: none; } }

@media (min-width: 37.5rem) {
  .navigation {
    top: 2rem;
    left: 2rem; }
  .navigation-list {
    padding-left: 2rem; }
  .navigation-list .menu-item span.hidden {
    display: none; }
  .navigation-list .menu-item {
    font-size: 2.5rem; } }

@media (min-width: 56.25rem) {
  .ham-menu {
    -webkit-transform: translateX(5px) translateY(9px);
            transform: translateX(5px) translateY(9px); }
  .navigation-list .menu-item span.hidden {
    display: inline-block; }
  .navigation-list {
    padding-left: 2.3rem; } }

@media (min-width: 37.5rem) {
  .page-title.project-title {
    margin-top: 8rem; } }

@media (min-width: 68.75rem) {
  .page-title.project-title {
    display: block;
    max-width: 64.5rem;
    margin-top: 10rem; } }

.project-container.about-content {
  margin-top: 5rem;
  padding: 3rem 2rem 8rem; }

.project-container.about-content p {
  max-width: 32.375rem;
  margin-left: auto;
  margin-right: auto; }

.highlight {
  color: black; }

.project-container.about-content .email {
  margin: 1.5625rem auto;
  text-align: center; }

.project-container.about-content .emphasis {
  max-width: 32.375rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 3rem;
  line-height: 1.5; }

.project-container.about-content .about-pic {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px; }

.project-container.about-content .about-pic img {
  border-radius: 1000px;
  overflow: hidden;
  object-fit: cover;
  box-shadow: 0 20px 60px 0px rgba(0, 0, 0, 0.3);
  height: 100px;
  width: 100px;
  flex: none; }

@media (min-width: 50rem) {
  .project-container.about-content .about-pic img {
    height: 125px;
    width: 125px; } }

.intro-banner {
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
  display: flex;
  align-items: center;
  -webkit-transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);
  transition: 0.3s all cubic-bezier(0.19, 1, 0.22, 1);
  font-family: 'Work Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  width: 100%;
  height: 70vh;
  overflow: hidden; }

.intro-banner-content {
  box-sizing: border-box;
  padding: 0 0.75rem;
  max-width: 68.75rem; }

.exit {
  -webkit-transform: translateY(150px);
          transform: translateY(150px);
  opacity: 0; }

.greeting {
  text-indent: -5px;
  line-height: 1.5;
  color: #000;
  font-weight: 100;
  letter-spacing: -0.005em; }

.sub-greeting {
  margin-top: 0;
  font-weight: 300;
  line-height: 1.2;
  font-size: 2em;
  color: #666;
  letter-spacing: -0.005em; }

.intro-banner a {
  font-size: 1.5rem; }

.intro-banner a,
.intro-banner a:visited {
  color: #ccc;
  text-decoration: none; }

.intro-banner a:hover,
.intro-banner a:focus {
  color: #222;
  text-decoration: underline; }

#content {
  display: none; }

.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px; }

.header,
.footer {
  height: calc(5vh-60px);
  padding: 30px 0; }

.post,
.page {
  padding: 30px;
  margin-bottom: 50px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); }

.entry-title {
  font-weight: 200;
  font-size: 30px;
  line-height: 1.3em;
  margin: 0 0 30px; }

.entry-title a {
  font-weight: 400; }

.text {
  position: relative;
  padding: 0;
  margin: 0;
  display: inline-block;
  font-size: 4em; }

.text:before, .text:after,
.content:before,
.content:after,
.more:before,
.more:after,
.again:before,
.again:after {
  opacity: 0; }

.visible:before, .visible:after {
  opacity: 1; }

.visible .again {
  outline: .3px #aaa solid; }

.text:before {
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.17);
  top: -3px;
  left: -3px; }

.text:after {
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.17);
  bottom: -3px;
  left: -3px; }

.content:before {
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.17);
  top: -3px;
  left: calc(100% + -3px); }

.content:after {
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.17);
  bottom: -3px;
  left: calc(100% + -3px); }

.more:before {
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.17);
  top: -3px;
  left: calc(50% + -3px); }

.more:after {
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.17);
  bottom: -3px;
  left: calc(50% + -3px); }

.again {
  outline: 0;
  padding: .2em; }
  .again:before {
    position: absolute;
    box-sizing: border-box;
    content: "";
    width: 8px;
    height: 8px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.17);
    top: calc(50% - 3px);
    left: -3px; }
  .again:after {
    position: absolute;
    box-sizing: border-box;
    content: "";
    width: 8px;
    height: 8px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.17);
    top: calc(50% - 3px);
    left: calc(100% + -3px); }
  .again:focus {
    outline: 0;
    outline: .3px #aaa solid; }
    .again:focus:hover {
      color: #000;
      cursor: text; }
  .again:hover {
    color: #44C0FF;
    cursor: move; }
  .again[active="true"]:focus {
    outline: none; }
  .again[active="true"]:hover {
    color: #000;
    cursor: move; }
  .again[selected="selected"]:hover {
    color: #000;
    cursor: move; }

.greeting {
  font-size: 2.25rem;
  padding-left: .3em; }
  @media (min-width: 37.5rem) {
    .greeting {
      font-size: 3rem;
      padding-left: .25em; } }
  @media (min-width: 1100px) {
    .greeting {
      font-size: 4.0625rem;
      padding-left: .22em; } }

@media (min-width: 37.5rem) {
  .intro-banner-content {
    padding: 0 2rem; }
  .sub-greeting {
    margin-bottom: 1.5rem; } }

@media (min-width: 1100px) {
  .intro-banner-content {
    width: 68.75rem;
    margin: 0 auto; } }

@charset "UTF-8";
.project-hero {
  width: 100vw;
  height: 300px;
  overflow: hidden;
  margin-bottom: 2.5rem;
  display: none; }
  .project-hero-img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.project-title {
  margin-left: 0.75rem;
  margin-top: 7.5rem;
  margin-bottom: 3rem;
  position: relative;
  width: calc( 100% - 2rem); }
  .project-title::after {
    content: "";
    position: absolute;
    bottom: 0rem;
    left: 0;
    width: 30px;
    height: 3px;
    background-color: black;
    -webkit-transform: translateY(1rem) translateX(1px);
            transform: translateY(1rem) translateX(1px); }

.project-back-link-container-default {
  text-align: center; }
  .project-back-link-container-default .project-back-link {
    position: relative;
    display: inline-block; }
    .project-back-link-container-default .project-back-link::before {
      content: "⟵";
      position: absolute;
      top: 50%;
      -webkit-transform: translateX(-130%) translateY(-50%);
              transform: translateX(-130%) translateY(-50%); }

.project-back-link-container-fixed {
  display: none; }

.project-back-link {
  display: block;
  width: 100vw;
  text-align: center;
  margin: 2rem 0 2.5rem 0; }

.project-container {
  box-sizing: border-box;
  width: 100vw;
  padding: 0 0.75rem;
  overflow: hidden; }
  .project-container a {
    color: #666;
    font-weight: 500;
    position: relative; }
    .project-container a:visited {
      color: #666; }
    .project-container a:hover {
      color: #000; }
  .project-container > h2 {
    margin-top: 3rem;
    margin-bottom: 2rem; }
  .project-container > h3 {
    margin-top: 2rem;
    margin-bottom: 1rem; }
  .project-container .chatbot-motion figure {
    background-color: #ededed;
    overflow: hidden; }
    .project-container .chatbot-motion figure img {
      -webkit-transform: scale(1.01);
              transform: scale(1.01); }
  .project-container figure {
    margin: 0;
    flex: 1 1;
    height: 100%; }
  .project-container img {
    width: 100%;
    margin: 0 auto;
    display: block;
    flex: 1 1;
    height: 100%;
    object-fit: cover; }
  .project-container .wp-block-video video {
    width: 100%;
    max-height: 90vh; }
  .project-container .wp-block-image {
    margin: 0 0 2rem 0;
    display: flex;
    flex-direction: column; }
    .project-container .wp-block-image > figcaption {
      order: 2;
      margin-top: 16px;
      color: #aaa; }
    .project-container .wp-block-image > a {
      order: 1; }
  .project-container .wp-block-gallery {
    display: flex; }
  .project-container .wp-block-gallery.columns-2 {
    width: 100%;
    list-style-type: none;
    padding: 0;
    flex-direction: column;
    overflow: hidden; }
  .project-container .blocks-gallery-item {
    overflow: hidden;
    margin-bottom: 0.75rem; }
  .project-container .wp-block-media-text {
    display: flex;
    flex-direction: column; }

.main-content-trans-enter {
  opacity: 0; }

.main-content-trans-enter-active {
  opacity: 1;
  -webkit-transition: opacity 200ms;
  transition: opacity 200ms; }

.main-content-trans-exit {
  opacity: 1; }

.main-content-trans-exit-active {
  opacity: 0;
  -webkit-transition: opacity 200ms;
  transition: opacity 200ms; }

@media only screen and (min-width: 28.125rem) {
  .wp-block-image {
    margin: 2rem 0 2.5rem 0; } }

@media (min-width: 37.5rem) {
  .project-container {
    padding: 0 2rem; }
    .project-container p {
      margin-bottom: 2rem; }
    .project-container .wp-block-gallery.columns-2 {
      margin: 2rem 0 2.5rem 0; }
  .project-title {
    margin-left: 2rem;
    width: calc( 100% - 4rem);
    margin-top: 8rem; } }

@media (min-width: 50rem) {
  .project-container p {
    max-width: 50%;
    line-height: 1.75; }
  .project-container p.codepen {
    max-width: 100%; }
  .project-container .wp-block-media-text .wp-block-media-text__content p {
    max-width: 100%; }
  .project-container .wp-block-gallery.columns-2 {
    flex-direction: row;
    flex: 1 1;
    margin: 2rem 0 2.5rem 0; }
  .project-container .wp-block-image {
    margin: 2rem 0 2.5rem 0; }
  .project-container .blocks-gallery-item + .blocks-gallery-item {
    margin-left: 1.5rem; }
  .project-container .wp-block-media-text {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 2rem 0 2.5rem 0; }
    .project-container .wp-block-media-text img {
      min-width: 100%;
      margin: 0 auto;
      display: block;
      flex: 1 1;
      min-height: 100%;
      object-fit: contain;
      max-height: 400px; }
    .project-container .wp-block-media-text > .wp-block-media-text__media, .project-container .wp-block-media-text > .wp-block-media-text__content {
      flex: 1 1; }
    .project-container .wp-block-media-text > .wp-block-media-text__media + .wp-block-media-text__content,
    .project-container .wp-block-media-text > .wp-block-media-text__content + .wp-block-media-text__media {
      margin-left: 1.5rem; }
    .project-container .wp-block-media-text .wp-block-media-text__content p {
      margin-top: 0; }
    .project-container .wp-block-media-text .wp-block-media-text__media {
      outline: 1px solid #eee; }
    .project-container .wp-block-media-text.has-media-on-the-right > .wp-block-media-text__content {
      order: 0;
      margin-left: 0; }
    .project-container .wp-block-media-text.has-media-on-the-right > .wp-block-media-text__media {
      order: 1;
      margin-left: 1.5rem; } }

@media (min-width: 68.75rem) {
  .project-container, .project-title {
    max-width: 68.75rem;
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .project-title {
    display: none; }
  .project-hero {
    position: relative;
    height: 400px;
    display: block;
    z-index: 1; }
    .project-hero::before {
      content: "";
      height: 100%;
      width: 100%;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.65)));
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
      /* IE6-9 */ }
  .project-title-hero {
    color: white;
    z-index: 1;
    position: absolute;
    width: 100vw;
    bottom: 0;
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 2.5rem; }
  .project-title-hero-text {
    padding-left: 2rem;
    display: block;
    max-width: 68.75rem;
    margin: 0 auto; }
  .project-back-link {
    -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    width: initial;
    width: auto; }
    .project-back-link.show {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      width: initial;
      width: auto; }
  .pinned {
    position: fixed;
    left: 2.4rem;
    top: 4rem;
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .project-back-link-container-fixed {
    position: fixed;
    overflow: hidden;
    display: none;
    z-index: 0; } }
  @media (min-width: 68.75rem) and (min-width: 1250px) {
    .project-back-link-container-fixed {
      display: block; } }

@media (min-width: 68.75rem) {
    .project-back-link-container-fixed .project-back-link {
      -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      opacity: 0;
      width: initial;
      width: auto; }
      .project-back-link-container-fixed .project-back-link.show {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
        opacity: 1;
        width: initial;
        width: auto; }
    .project-back-link-container-fixed.pinned {
      left: 2.4rem;
      top: 4rem;
      -webkit-transform-origin: 0 50%;
              transform-origin: 0 50%;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); } }

#lightboxOverlay {
  position: fixed !important;
  top: 0;
  left: 0;
  height: 100% !important;
  width: 100% !important; }

#lightbox {
  position: fixed !important;
  top: 50% !important;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.lb-outerContainer {
  border-radius: 0px;
  width: 90% !important;
  height: 100% !important;
  background-color: transparent; }
  .lb-outerContainer .lb-image {
    object-fit: contain;
    width: 90vw !important;
    height: 90vh !important;
    border: none; }

.lb-close {
  position: absolute;
  top: -25px;
  right: 20px; }

.password-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: #eee;
  top: 0;
  left: 0;
  z-index: 1; }

.password-trans-enter {
  opacity: 0; }

.password-trans-enter-active {
  opacity: 1;
  -webkit-transition: opacity 200ms;
  transition: opacity 200ms; }

.password-trans-exit {
  opacity: 1; }

.password-trans-exit-active {
  opacity: 0;
  -webkit-transition: opacity 200ms;
  transition: opacity 200ms; }

.password-container {
  display: flex;
  flex-direction: column;
  align-items: center; }

.password-input-container {
  position: relative; }

.password-input {
  height: 3rem;
  text-indent: 1rem;
  width: 20rem;
  outline: 2px solid #ccc;
  outline-offset: 0;
  border: 0;
  -webkit-transition: .2s outline;
  transition: .2s outline; }
  .password-input:focus {
    outline: 2px solid #222;
    outline-offset: 0; }

.password-submit {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: .4s ease-in-out;
  transition: .4s ease-in-out;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 0; }
  .password-submit.submit-available {
    visibility: visible;
    opacity: .4;
    outline: 2px solid transparent;
    outline-offset: 0;
    border: 0;
    -webkit-transition: .2s;
    transition: .2s; }
    .password-submit.submit-available:focus {
      opacity: 1;
      outline: 2px solid #222;
      outline-offset: 0; }
    .password-submit.submit-available:hover {
      opacity: 1; }

.lock-image {
  opacity: .5;
  margin-bottom: 1rem; }

#root {
  height: 100vh; }

.App {
  width: 100vw;
  overflow: hidden; }

.home-project-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5rem;
  padding-bottom: 5rem; }

.project {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem; }

.home-project-title {
  display: flex; }

.home-project-title a {
  font-size: 1.125rem;
  line-height: 1.3;
  font-weight: 500;
  color: #444;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.project-image-container {
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  height: 63vw;
  overflow: hidden;
  border: 1px solid #dedede; }
  .project-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.project-to-link {
  margin-top: 20px; }

@media (min-width: 37.5rem) {
  .project {
    width: calc( 50% - 1.25rem);
    margin-bottom: 2.5rem; }
    .project:nth-child(even) {
      margin-left: 2.5rem; }
    .project .project-image-container {
      -webkit-transition: 0.55s all cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: 0.55s all cubic-bezier(0.165, 0.84, 0.44, 1);
      height: 27vw; }
    .project:hover .project-image-container {
      -webkit-transform: scale(1.02) translateY(-5px);
              transform: scale(1.02) translateY(-5px);
      box-shadow: 0 40px 40px 5px rgba(0, 0, 0, 0.15); }
    .project:hover .project-to-link, .project:hover .home-project-title a {
      color: #222; }
  .project-container {
    overflow: visible;
    overflow: initial; } }

@media (min-width: 1100px) {
  .project {
    min-width: 0px;
    flex-basis: 30.75%;
    margin-bottom: 2.5rem;
    margin-left: 2.5rem; }
    .project:nth-child(3n+1) {
      margin-left: 0; }
    .project .project-image-container {
      height: 18.75rem; } }

