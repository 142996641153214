@import '../common';

// Font Import
@import url('https://fonts.googleapis.com/css?family=Red+Hat+Text|Work+Sans&display=swap');

.intro-banner {
    transform: translateY(50px);
    display: flex;  
    align-items: center;
    transition: .3s all cubic-bezier(0.19, 1, 0.22, 1);
    font-family: 'Work Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    width: 100%;
    height: 70vh;
    overflow: hidden
}

.intro-banner-content {
    box-sizing: border-box;
    padding: 0 $port-space-sm;
    max-width: rem($port-wrapper);
}

.exit {
    transform: translateY(150px);
    opacity: 0;
}

.greeting {
    text-indent: -5px;
    line-height: 1.5;
    color: #000;
    font-weight: 100;
    letter-spacing: -0.005em;
}

.sub-greeting {
    margin-top: 0;
    font-weight: 300;
    line-height: 1.2;
    font-size: 2em;
    color: #666;
    letter-spacing: -0.005em;
}

.intro-banner {
    a {
        font-size: 1.5rem;
    }
    
    a,
    a:visited {
        color: #ccc;
        text-decoration: none;
    }

    a:hover,
    a:focus {
        color: #222;
        text-decoration: underline;
    }
}


#content {
    display: none;
}

.container {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 20px;
}

.header,
.footer {
    height: calc(5vh-60px);
    padding: 30px 0;
}

.post,
.page {
    padding: 30px;
    margin-bottom: 50px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.entry-title {
    font-weight: 200;
    font-size: 30px;
    line-height: 1.3em;
    margin: 0 0 30px;
}

.entry-title a {
    font-weight: 400;
}

.text {
    position: relative;
    padding: 0;
    margin: 0;
    display: inline-block;
    font-size: 4em;
}

.text,
.content,
.more,
.again {
    &:before,
    &:after {
        opacity: 0;
    }
}

.visible {
    &:before,
    &:after {
        opacity: 1;
    }
    & .again {
        outline: .3px #aaa solid;
    }
}

@mixin point-style {
    position: absolute;
    box-sizing: border-box;
    content: "";
    width: 8px;
    height: 8px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.17);
}

// top left, bottom left
.text {
    &:before {
        @include point-style;
        top: -3px;
        left: -3px;
    }
    &:after {
        @include point-style;
        bottom: -3px;
        left: -3px;
    }
}

// top right, bottom right
.content {
    &:before {
        @include point-style;
        top: -3px;
        left: calc(100% + -3px);
    }
    &:after {
        @include point-style;
        bottom: -3px;
        left: calc(100% + -3px);
    }
}

// middle top, middle bottom
.more {
    &:before {
        @include point-style;
        top: -3px;
        left: calc(50% + -3px);
    }
    &:after {
        @include point-style;
        bottom: -3px;
        left: calc(50% + -3px);
    }
}

// middle right, middle left
.again {
    outline: 0;
    padding: .2em;
    &:before {
        @include point-style;
        top: calc(50% - 3px);
        left: -3px;
    }
    &:after {
        @include point-style;
        top: calc(50% - 3px);
        left: calc(100% + -3px);
    }
    &:focus {
        outline: 0;
        outline: .3px #aaa solid;
        &:hover {
            color: #000;
            cursor: text;
        }
    }
    &:hover {
        color: #44C0FF;
        cursor: move;
    }
    &[active="true"] {
        &:focus {
            outline: none;
        }
    }
    &[active="true"]:hover {
        color: #000;
        cursor: move;
    }
    &[selected="selected"]:hover {
        color: #000;
        cursor: move;
    }
}

.greeting {
    font-size: rem(36px);
    padding-left: .3em;

	@media (min-width: $port-breakpoint-md) {
        font-size: rem(48px);
        padding-left: .25em;
    }
    
	@media (min-width: $port-breakpoint-lg) {
        font-size: rem(65px);
        padding-left: .22em;
    }
}

@media (min-width: $port-breakpoint-md) {
    .intro-banner-content {
        padding: 0 $port-space-lg;
    }

    .sub-greeting {
        margin-bottom: $port-space-md;
    }
}

@media (min-width: $port-breakpoint-lg) {
    .intro-banner-content {
        width: rem($port-wrapper);
        margin: 0 auto;
    }
}
