@import './common';

#root {
    height: 100vh;
}

.App {
    width: 100vw;
    overflow: hidden;
}

.home-project-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: $port-space-xlg*2;
    padding-bottom: $port-space-xlg*2;
}

.project {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
}

.home-project-title {
    display: flex;
}

.home-project-title a {
    font-size: rem(18px);
    line-height: 1.3;
    font-weight: 500;
    color: #444;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.project-image-container {
    border-radius: 4px;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
    height: 63vw;
    overflow: hidden;
    border: 1px solid #dedede;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.project-to-link {
    margin-top: 20px;
}

@media (min-width: $port-breakpoint-md) {
    .project {
        width: calc( 50% - #{$port-space-xlg / 2} );
        margin-bottom: $port-space-xlg;
        
        &:nth-child(even) {
            margin-left: $port-space-xlg;
        }

        .project-image-container {
            transition: .55s all cubic-bezier(0.165, 0.84, 0.44, 1);
            height: 27vw;
        }

        &:hover {
            .project-image-container {
                transform: scale(1.02) translateY(-5px);
                box-shadow: 0 40px 40px 5px rgba(0,0,0,.15);
            }

            .project-to-link, .home-project-title a {
                color: #222;
            }
        }
    }

    .project-container {
        overflow: initial;
    }
}

@media (min-width: $port-breakpoint-lg) {
    .project {
        min-width: 0px;
        flex-basis: 30.75%;
        margin-bottom: $port-space-xlg;
        margin-left: $port-space-xlg;
        
        &:nth-child(3n+1) {
            margin-left: 0;
        }

        .project-image-container {
            height: rem(300px);
        }
    }
}