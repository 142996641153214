@import '../common';

@media (min-width: $port-breakpoint-md) {
    .page-title.project-title {
        margin-top: 8rem;
    }
}

@media (min-width: rem($port-wrapper)) {
    .page-title.project-title {
        display: block;
        max-width: rem(1032px);
        margin-top: 10rem;
    }
}
